import { Button, Col, Image, Row, Typography, Table } from "antd";
import React, { useEffect } from "react";
import "../styles/orderResult.css";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import iconDate from "../assets/images/date.png";
import iconEmail from "../assets/images/iconEmail1.png";
import iconMap from "../assets/images/iconMap.png";
import iconMic from "../assets/images/iconMic.png";
import iconUser from "../assets/images/iconUser.png";
import phoneOutline from "../assets/images/phoneOutline.png";
import iconTime from "../assets/images/time.png";
import noData from "../assets/images/no_result.png";
import { useNavigate } from "react-router-dom";
import { getBranchs } from "../features/branchSlide";
import { moneyFormat } from "../untils/constanst";
const SearchOrder = (props) => {
	const { branchs, bookedRooms } = useSelector((state) => state.branchs);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getBranchs());
	}, []);
	const navigate = useNavigate();
	const onBack = () => {
		navigate("/order");
	};

	useEffect(() => {
		window.scrollTo(0, 0, { behavior: "smooth" });
	}, []);

	const columns = [
		{
			title: "Cơ sở",
			dataIndex: "name",
			key: "name",
			render: (text, value) => (
				<Typography.Text>
					{branchs &&
						branchs?.find((item) => item._id === value?.branch_id) && (
							<>
								<p className="m-0">
									{branchs?.find((item) => item._id === value?.branch_id)?.name}
								</p>
								<p className="m-0">
									{
										branchs?.find((item) => item._id === value?.branch_id)
											?.address
									}
								</p>
							</>
						)}
				</Typography.Text>
			),
		},
		{
			title: "Loại phòng",
			dataIndex: "roomType",
			key: "roomType",
			render: (text, value) => {
				const room = branchs.find((val) => val?._id === value.branch_id);
				const time = new Date(value.start_time * 1000);
				return (
					<>
						<p className="m-0">
							{value?.room_type === "small" &&
								`S Box (từ ${room[value?.room_type]?.capacity} người)`}
							{value?.room_type === "medium" &&
								`M Box (từ ${room[value?.room_type]?.capacity} người)`}
							{value?.room_type === "large" &&
								`L Box (trên ${room[value?.room_type]?.capacity} người)`}
						</p>
						<p className="m-0">
							{value?.room?.name}
						</p>
					</>
				);
			},
		},
		{
			title: "Thời gian hát",
			dataIndex: "time",
			key: "time",
			render: (text, value) => {
				const time = new Date(value.start_time * 1000);
				return (
					<>
						<p className="m-0">{moment(time).format("DD/MM/YYYY HH:ss")}</p>
					</>
				);
			},
		},
		{
			title: "Thời lượng hát",
			dataIndex: "time_box",
			key: "time_box",
			render: (text, value) => `${text} Phút`,
		},
		{
			title: "Thành tiền",
			dataIndex: "total_amount",
			key: "total_amount",
			render: (text, value) => `${moneyFormat(value?.total_amount)} VND`,
		},
		{
			title: "Số điện thoại cơ sở",
			dataIndex: "phone",
			key: "phone",
			render: (text, value) => {
				const room = branchs.find((val) => val?._id === value.branch_id);
				return (
					<>
						<p className="m-0">{room?.phone}</p>
					</>
				);
			},
		},
	];

	return (
		<div className="bg-image">
			<div className="bg-search-order">
				{bookedRooms && bookedRooms?.length > 0 ? (
					<div>
						<Typography.Title
							level={2}
							className="text-center text-header-search"
						>
							Tra cứu thông tin đặt phòng
						</Typography.Title>
						<div
							style={{ padding: "10px 40px 30px 40px" }}
							className="wrapper-search-laptop"
						>
							<Typography.Text style={{ fontSize: "18px", fontWeight: "600" }}>
								Thông tin người đặt phòng
							</Typography.Text>
							<Row className="mt-2 box-wrapper">
								<Col xs={24} sm={12} md={12} lg={8} xl={8}>
									<div className="d-flex align-items-center mt-1 mb-1">
										<Image
											className="image-icon"
											preview={false}
											src={iconUser}
										/>
										<Typography.Text>{bookedRooms[0]?.name}</Typography.Text>
									</div>
								</Col>
								<Col xs={24} sm={12} md={12} lg={8} xl={8}>
									<div className="d-flex align-items-center mt-1 mb-1">
										<Image
											className="image-icon"
											preview={false}
											src={iconEmail}
										/>
										<Typography.Text>{bookedRooms[0]?.email}</Typography.Text>
									</div>
								</Col>
								<Col xs={24} sm={12} md={12} lg={8} xl={8}>
									<div className="d-flex align-items-center mt-1 mb-1">
										<Image
											className="image-icon"
											preview={false}
											src={phoneOutline}
										/>
										<Typography.Text>{bookedRooms[0]?.mobile}</Typography.Text>
									</div>
								</Col>
							</Row>

							<Typography.Text
								style={{
									fontSize: "18px",
									fontWeight: "600",
									margin: "20px 0px",
									display: "block",
								}}
							>
								Danh sách các phòng đã đặt
							</Typography.Text>

							<Table columns={columns} dataSource={bookedRooms} />
						</div>
						<Row className="px-2 wrapper-search-mobile">
							{bookedRooms?.map((item, index) => {
								const room = branchs.find((val) => val?._id === item.branch_id);
								const time = new Date(item.start_time * 1000);
								const start_time = new Date(item?.start_time * 1000);
								const end_time = new Date(item?.end_time * 1000);
								return (
									<Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
										<div className="mb-2 box-wrapper">
											<div className="border-bottom-outline">
												<Typography.Title
													style={{ fontSize: "18px", fontWeight: "600" }}
													level={4}
													className="mt-0"
												>
													Thông tin đặt chỗ
												</Typography.Title>
												<div className="d-flex mb-1">
													<Image
														className="image-icon"
														preview={false}
														src={iconMap}
													/>
													<Typography.Text>
														{room && room?.name && (
															<Typography.Text>
																{room?.name} - {room?.address}
															</Typography.Text>
														)}
													</Typography.Text>
												</div>
												<div className="d-flex mb-1">
													<Image
														className="image-icon"
														preview={false}
														src={phoneOutline}
													/>
													<Typography.Text>
														Điện thoại liên hệ: {room?.phone}
													</Typography.Text>
												</div>
												<div className="d-flex mb-1">
													<Image
														className="image-icon"
														preview={false}
														src={iconMic}
													/>
													<Typography.Text className="">
														{item?.room_type === "small" &&
															`S Box (từ ${
																room[item?.room_type]?.capacity
															} người) - ${
																item?.room?.name
															}`}
														{item?.room_type === "medium" &&
															`M Box (từ ${
																room[item?.room_type]?.capacity
															} người) - ${
																item?.room?.name
															}`}
														{item?.room_type === "large" &&
															`L Box (trên ${
																room[item?.room_type]?.capacity
															} người) - ${
																item?.room?.name
															}`}
													</Typography.Text>
												</div>
												<div className="d-flex">
													<Image
														className="image-icon"
														preview={false}
														src={iconDate}
													/>
													<Typography.Text>
														{moment(start_time).format("DD/MM")} -{" "}
														<Image
															className="image-icon"
															preview={false}
															src={iconTime}
														/>
														Từ {moment(start_time).format("HH:mm")} - Đến{" "}
														{moment(end_time).format("HH:mm")}
													</Typography.Text>
												</div>
											</div>
											<div>
												<Typography.Title
													level={4}
													style={{ fontSize: "18px", fontWeight: "600" }}
												>
													Thông tin liên lạc
												</Typography.Title>
												<div className="d-flex mb-1 align-items-center">
													<Image
														className="image-icon"
														preview={false}
														src={iconUser}
													/>
													<Typography.Text>{item?.name}</Typography.Text>
												</div>
												<div className="d-flex mb-1 align-items-center">
													<Image
														className="image-icon"
														preview={false}
														src={iconEmail}
													/>
													<Typography.Text>
														{item?.email ?? "N/A"}
													</Typography.Text>
												</div>
												<div className="d-flex mb-1 align-items-center">
													<Image
														className="image-icon"
														preview={false}
														src={phoneOutline}
													/>
													<Typography.Text>{item?.mobile}</Typography.Text>
												</div>
											</div>
										</div>
									</Col>
								);
							})}
						</Row>
					</div>
				) : (
					<div>
						<Typography.Title
							level={2}
							className="text-center text-header-search"
						>
							Tra cứu thông tin đặt phòng
						</Typography.Title>

						<div className="text-center mt-2 pt-2">
							<Image src={noData} preview={false} />
						</div>
						<p
							className="text-center text-header-search-content"
							style={{ fontSize: "16px", fontWeight: "600" }}
						>
							Không tìm thấy thông tin đặt phòng của bạn.{" "}
						</p>
						<p
							className="text-center text-header-search-content"
							style={{ fontSize: "16px", fontWeight: "600" }}
						>
							Vui lòng thử lại số điện thoại đặt phòng
						</p>
					</div>
				)}
				<div className="text-center d-flex justify-content-center mt-2 mb-2">
					<Button onClick={onBack} htmlType="button" className="btn-search">
						Về trang chủ
					</Button>
				</div>
			</div>
		</div>
	);
};

SearchOrder.propTypes = {};

export default SearchOrder;
